import paronApi from '@/utils/paron-api'
export default {
    getAllTeams() {
        return paronApi.get('team/all')
    },

    getTeamById(id) {
        return paronApi.get(`team/${id}`)
    },

    createTeam(data) {
        return paronApi.post('team/new', data)
    },

    editTeamById(id, data) {
        return paronApi.post(`team/${id}/edit`, data)
    },

    deleteTeamById(id) {
        return paronApi.delete(`team/${id}`)
    },

    getTeamsByFilter(page, filter, sortOption, order) {
        var path = page ? `team/filtered?page=${page}&per_page=10&` : 'team/filtered?'
        if (sortOption) {
            path += `sortOption=${sortOption}&order=${order}`
        }
        return paronApi.post(path, filter)
    },

    toggleApproved(id) {
        return paronApi.post(`team/${id}/toggleApprove`)
    }

}
